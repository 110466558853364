import {AlistRepresentation} from '@aztrix/sdk';

export interface AListStateModel extends Partial<AlistRepresentation> {
  modelStatus: 'pending' | 'ready';
}

export interface StateModel {
  server: {[id: string]: AListStateModel};
  client: {[id: string]: AlistRepresentation};
  activeAlistId: string | undefined;
  activeAlistLanguageCode: string | undefined;
  alistOwnerId: string | undefined;
}

export const defaults: StateModel = {
  server: {},
  client: {},
  activeAlistId: undefined,
  activeAlistLanguageCode: undefined,
  alistOwnerId: undefined,
};
