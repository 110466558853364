import {Injectable} from '@angular/core';
import {AlistRepresentation, AlistsService} from '@aztrix/sdk';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {map, of, switchMap, tap} from 'rxjs';

import {Clear, Init} from '../actions';
import {
  RemoveAlist,
  SetActiveAlistId,
  SetActiveAlistLanguageCode,
  SetAlistOwnerId,
  SetClientAlist,
  SetClientAlists,
  SetServerAlist,
  SetServerAlists,
} from './alist.actions';
import {AListStateModel, defaults, StateModel} from './alist-state.model';

@State<StateModel>({
  name: 'alist',
  defaults,
})
@Injectable()
export class AlistState {
  constructor(private _alists: AlistsService) {}

  @Selector()
  static clientAlists(state: StateModel): {[id: string]: AlistRepresentation} {
    return state?.client || {};
  }

  @Selector()
  static serverAlists(state: StateModel): {[id: string]: AListStateModel} {
    return state?.server || {};
  }

  @Selector()
  static clientAlist(state: StateModel): AlistRepresentation | undefined {
    return state?.client[state?.activeAlistId || ''] || undefined;
  }

  @Selector()
  static serverAlist(state: StateModel): AListStateModel | undefined {
    return Object.values(state.server).find(
      (alist) => alist.modelStatus === 'ready' && alist.id === state.activeAlistId
    );
  }

  @Selector()
  static activeAlistId(state: StateModel): string | undefined {
    return state?.activeAlistId;
  }

  @Selector()
  static activeAlistLanguageCode(state: StateModel): string | undefined {
    return state?.activeAlistLanguageCode;
  }

  @Selector()
  static activeAlistOwnerId(state: StateModel): string | undefined {
    return state?.alistOwnerId;
  }

  @Selector([AlistState.clientAlist, AlistState.activeAlistLanguageCode])
  static clientAlistLanguage(
    _state: StateModel,
    alist: AlistRepresentation,
    languageCode: string
  ): AlistRepresentation | undefined {
    return alist.languages?.find((l) => l.language === languageCode);
  }

  @Selector([AlistState.serverAlist, AlistState.activeAlistLanguageCode])
  static serverAlistLanguage(
    _state: StateModel,
    alist: AlistRepresentation,
    languageCode: string
  ): AlistRepresentation | undefined {
    return alist.languages?.find((l) => l.language === languageCode);
  }

  @Action(Init)
  init(context: StateContext<StateModel>) {
    return this._alists.getAlists().pipe(
      switchMap((alists) => {
        if (alists.length > 0) {
          return of(alists);
        } else {
          // when there's no alist on login, add a new one
          return this._createEmptyAlist$();
        }
      }),
      tap((alists) => {
        this._setServerAndClientAlists(context, alists, alists);
      })
    );
  }

  @Action(Clear)
  clear({setState}: StateContext<StateModel>) {
    setState({...defaults});
  }

  @Action(SetClientAlists)
  setClientAlists({getState, patchState}: StateContext<StateModel>, {alists}: SetClientAlists) {
    const state = getState();
    const clientAlists = state.client;

    alists.map((alist) => ('language' in alist ? alist : {...alist, language: 'en'}));

    const newAlists = this._newClientAlists(alists);
    patchState({client: {...clientAlists, ...newAlists}});
  }

  @Action(SetServerAlists)
  setServerAlists(context: StateContext<StateModel>, {alists}: SetServerAlists) {
    const state = context.getState();
    const serverAlists = state.server;

    const newServerAlists = Object.values(alists).map((alist) => ({
      ...alist,
      modelStatus: 'ready',
    }));
    const newAlists = this._newServerAlists(<AListStateModel[]>newServerAlists);
    context.patchState({
      server: {
        ...serverAlists,
        ...newAlists,
      },
    });

    return this._checkAlistsActiveIds(context);
  }

  @Action(SetClientAlist)
  setClientAlist({getState, patchState}: StateContext<StateModel>, {alist}: SetClientAlist) {
    patchState({
      client: {...getState().client, [alist.id || '']: alist},
    });
  }

  @Action(SetServerAlist)
  setServerAlist(context: StateContext<StateModel>, {alist}: SetServerAlist) {
    context.patchState({
      server: {
        ...context.getState().server,
        [alist.id || '']: {...alist, modelStatus: 'ready'},
      },
    });

    return this._checkAlistsActiveIds(context);
  }

  @Action(SetActiveAlistId)
  setActiveAlistId(context: StateContext<StateModel>, {alistId}: SetActiveAlistId) {
    if (alistId) {
      context.patchState({
        activeAlistId: alistId,
      });

      return this._checkAlistsActiveIds(context);
    }
  }

  @Action(SetActiveAlistLanguageCode)
  setActiveAlistLanguageCode(
    {patchState}: StateContext<StateModel>,
    {languageCode}: SetActiveAlistLanguageCode
  ) {
    if (languageCode) {
      patchState({
        activeAlistLanguageCode: languageCode,
      });
    }
  }

  @Action(SetAlistOwnerId)
  setAlistOwnerId({patchState}: StateContext<StateModel>, {ownerId}: SetAlistOwnerId) {
    if (ownerId) {
      patchState({
        alistOwnerId: ownerId,
      });
    }
  }

  @Action(RemoveAlist)
  removeAlist(context: StateContext<StateModel>, {id}: RemoveAlist) {
    const state = context.getState();
    const alistToRemove = Object.values(state.server).find((alist) => alist.id === id);
    const resultClientAlists = Object.values(state.client).filter(
      (alist) => alist.id !== alistToRemove?.id
    );
    const resultServerAlists = Object.values(state.server).filter(
      (alist) => alist.id !== alistToRemove?.id
    );

    if (resultServerAlists.length > 0) {
      return this._setServerAndClientAlists(context, resultClientAlists, resultServerAlists);
    } else {
      // when the last alist is removed, add a new one
      return this._createEmptyAlist$().pipe(
        tap((alists) => {
          this._setServerAndClientAlists(context, alists, alists);
        })
      );
    }
  }

  private _checkAlistsActiveIds(context: StateContext<StateModel>) {
    const state = context.getState();

    if (!(state.client || {})[state.activeAlistId || '']) {
      const newActiveId = Object.keys(state.client)[0];
      this.setActiveAlistId(context, new SetActiveAlistId(newActiveId));
    }

    const clientAlist = AlistState.clientAlist(state);
    if (!this._alistLanguageExistsWithinAlist(context, clientAlist ?? {})) {
      this.setActiveAlistLanguageCode(
        context,
        new SetActiveAlistLanguageCode(
          clientAlist?.languages ? clientAlist.languages[0].language || '' : ''
        )
      );
    }
  }

  private _alistLanguageExistsWithinAlist(
    context: StateContext<StateModel>,
    alist: AlistRepresentation
  ) {
    const state = context.getState();

    if (alist) {
      if (alist.languages?.find((l) => l.language === state.activeAlistLanguageCode)) {
        return true;
      }
    }
    return false;
  }

  private _setServerAndClientAlists(
    context: StateContext<StateModel>,
    clientAlists: AlistRepresentation[],
    serverAlists: AListStateModel[] | AlistRepresentation[]
  ) {
    const newServerAlists = serverAlists.map((alist) => ({
      ...alist,
      modelStatus: 'ready',
    }));

    const state = context.getState();
    let activeAlistId = state.activeAlistId;
    const activeServerAlist = serverAlists.find((serverAlist) => serverAlist.id === activeAlistId);
    if (!activeServerAlist) {
      activeAlistId = Object.values(serverAlists)[0]?.id || '';
    }

    let activeAlistLanguageCode = state.activeAlistLanguageCode;
    const activeServerAlist2 = serverAlists.find((serverAlist) => serverAlist.id === activeAlistId);
    const activeServerAlistLanguage = activeServerAlist2?.languages?.find(
      (language) => language.language === activeAlistLanguageCode
    );
    if (!activeServerAlistLanguage) {
      activeAlistLanguageCode = activeServerAlist2?.languages
        ? activeServerAlist2.languages[0]?.language || ''
        : '';
    }

    return context.patchState({
      client: this._newClientAlists(clientAlists),
      server: this._newServerAlists(<AListStateModel[]>newServerAlists),
      alistOwnerId: serverAlists[0].ownerId,
      activeAlistId,
      activeAlistLanguageCode,
    });
  }

  private _newClientAlists(alists: AlistRepresentation[]): {[id: string]: AlistRepresentation} {
    return alists.reduce(
      (prev, alist) => {
        if (alist.id !== undefined) {
          prev[alist.id] = alist;
        }
        return prev as {[id: string]: AlistRepresentation};
      },

      {} as {[id: string]: AlistRepresentation}
    );
  }

  private _newServerAlists(alists: AListStateModel[]): {[id: string]: AListStateModel} {
    return alists.reduce(
      (prev, alist) => {
        // Check if alist is AListStateModel

        if (alist.id !== undefined) {
          prev[alist.id] = alist;
        }
        return prev as {[id: string]: AListStateModel};
      },
      {} as {[id: string]: AListStateModel}
    );
  }

  private _createEmptyAlist$() {
    return this._alists.createAlist({}).pipe(map((alist) => [alist]));
  }
}
