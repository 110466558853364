import {deepEqual} from '@aztrix/helpers';
import {AlistRepresentation} from '@aztrix/sdk';
import {Selector} from '@ngxs/store';

import {AlistState} from './alist.state';
import {AListStateModel} from './alist-state.model';

export const ALIST_DEFAULT_APPEARANCE = {
  backgroundColor: '#FFC107',
  backgroundImageEnabled: false,
  textColor: '#363F48',
  buttonColor: '#FFC107',
  buttonFontColor: '#FFFFFF',
  whitelabel: false,
};

export const ALIST_DEFAULT_SETTINGS = {
  analytics: {facebook: {active: false}, google: {active: false}},
};

// @dynamic
export class AlistStateQuery {
  @Selector([AlistState.serverAlist, AlistState.clientAlist])
  static synced(server: AListStateModel, client: AlistRepresentation): boolean {
    if (!server || !client) {
      return false;
    }
    return deepEqual(
      {
        ...server,
        modelStatus: undefined,
        ownerId: undefined,
        claimCode: undefined,
        domainEnabled: server.domainEnabled || false,
        languages: server.languages?.map((alistLanguage) => {
          return {
            ...alistLanguage,
            id: undefined,
            items: alistLanguage.items?.map((item) => ({...item, id: undefined})),
            appearance: alistLanguage.appearance || ALIST_DEFAULT_APPEARANCE,
            settings: alistLanguage.settings || ALIST_DEFAULT_SETTINGS,
          };
        }),
      },
      {
        ...client,
        modelStatus: undefined,
        ownerId: undefined,
        claimCode: undefined,
        domainEnabled: client.domainEnabled || false,
        languages: client.languages?.map((alistLanguage) => {
          return {
            ...alistLanguage,
            id: undefined,
            items: alistLanguage.items?.map((item) => ({...item, id: undefined})),
            appearance: alistLanguage.appearance || ALIST_DEFAULT_APPEARANCE,
            settings: alistLanguage.settings || ALIST_DEFAULT_SETTINGS,
          };
        }),
      }
    );
  }

  @Selector([AlistState.serverAlists, AlistState.clientAlists])
  static allSynced(
    serverLists: {[id: string]: AListStateModel},
    clientLists: {[id: string]: AlistRepresentation}
  ): boolean {
    if (Object.values(serverLists).length !== Object.values(clientLists).length) {
      return false;
    } else {
      for (const [id, serverList] of Object.entries(serverLists)) {
        if (!this.synced(serverList, clientLists[id])) {
          return false;
        }
      }
      return true;
    }
  }

  @Selector([AlistState.clientAlists])
  static clientAlistById(clientAlists: {
    [id: string]: AlistRepresentation;
  }): (id: string) => AlistRepresentation {
    return (id: string) => {
      return clientAlists[id];
    };
  }

  @Selector([AlistState.serverAlists])
  static serverAlistById(serverAlists: {
    [id: string]: AListStateModel;
  }): (id: string) => AListStateModel {
    return (id: string) => {
      return serverAlists[id];
    };
  }

  @Selector([AlistState.clientAlists])
  static clientAlistByName(clientAlists: {
    [id: string]: AlistRepresentation;
  }): (name: string) => AlistRepresentation | undefined {
    return (name: string) => Object.values(clientAlists).find((l) => l.name === name);
  }
}
