import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@aztrix/translate';
import {AlistLanguageRepresentation, AlistRepresentation, AlistsService} from '@aztrix/sdk';
import {Store} from '@ngxs/store';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  defer,
  distinctUntilChanged,
  first,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import {
  RemoveAlist,
  SetActiveAlistId,
  SetActiveAlistLanguageCode,
  SetClientAlist,
  SetClientAlists,
  SetServerAlist,
  SetServerAlists,
} from './alist.actions';
import {AlistStateQuery} from './alist.query';
import {AlistState} from './alist.state';
import {AListStateModel} from './alist-state.model';
import {deepEqual} from '@aztrix/helpers';

@Injectable({providedIn: 'root'})
export class AListService {
  clientAlists$: Observable<AlistRepresentation[]> = this._store
    .select(AlistState.clientAlists)
    .pipe(map((alists) => Object.values(alists)));
  serverAlists$: Observable<AListStateModel[]> = this._store
    .select(AlistState.serverAlists)
    .pipe(map((alists) => Object.values(alists)));
  clientAlist$: Observable<AlistRepresentation | undefined> = this._store.select(
    AlistState.clientAlist
  );
  serverAlist$: Observable<AListStateModel | undefined> = this._store.select(
    AlistState.serverAlist
  );
  clientAlistLanguage$: Observable<AlistLanguageRepresentation | undefined> = this._store.select(
    AlistState.clientAlistLanguage
  );
  serverAlistLanguage$: Observable<AlistLanguageRepresentation | undefined> = this._store.select(
    AlistState.serverAlistLanguage
  );

  activeAlistId$: Observable<string | undefined> = this._store.select(AlistState.activeAlistId);
  activeAlistLanguageCode$: Observable<string | undefined> = this._store.select(
    AlistState.activeAlistLanguageCode
  );
  activeAlistOwnerId$: Observable<string | undefined> = this._store.select(
    AlistState.activeAlistOwnerId
  );

  activeAlistLanguageId$ = this.clientAlistLanguage$.pipe(
    map((alistLanguage) => alistLanguage?.id),
    distinctUntilChanged()
  );

  synced$: Observable<boolean> = this._store.select(AlistStateQuery.synced);
  allSynced$: Observable<boolean> = this._store.select(AlistStateQuery.allSynced);

  errors$ = new BehaviorSubject<Record<string, string>>({});
  saveTimestamp$ = new BehaviorSubject<Date | null>(null);

  lastError$ = this.errors$.pipe(
    map((errors) => {
      const errorsList = Object.values<string>(errors);
      return errorsList[errorsList.length - 1];
    })
  );

  constructor(
    private _store: Store,
    private _alists: AlistsService,
    private _snackbar: MatSnackBar,
    private _translateService: TranslateService,
    router: Router,
    route: ActivatedRoute
  ) {
    this.lastError$.subscribe((error) => {
      if (error) {
        this._snackbar.open(error, this._translateService.instant('label.close'), {
          duration: 5000,
        });
      }
    });

    this._store
      .select(AlistState.clientAlists)
      .pipe(
        distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
        debounceTime(2000),
        switchMap((clientLists) =>
          this._store
            .selectOnce(AlistState.serverAlists)
            .pipe(map((serverLists) => ({serverLists, clientLists})))
        ),
        switchMap(({serverLists, clientLists}) => {
          const observables: Observable<AlistRepresentation>[] = [];
          for (const [id, serverList] of Object.entries(serverLists)) {
            const clientList = clientLists[id];
            if (clientList && !AlistStateQuery.synced(serverList, clientList)) {
              observables.push(
                this._alists.updateAlist(clientList.id || '', clientList).pipe(
                  tap((alist) => {
                    this.saveTimestamp$.next(new Date());
                    this.setServerAlist(alist);
                    this._clearAlistError(clientList.id || '');
                  }),
                  catchError((err) => {
                    this.alistError(clientList.id || '', err);
                    return of(err);
                  })
                )
              );
            }
          }
          return forkJoin(observables);
        })
      )
      .subscribe();

    route.queryParamMap
      .pipe(
        switchMap((queries) => {
          const name = queries?.get('list');
          return this._store
            .select(AlistStateQuery.clientAlistByName)
            .pipe(map((filterFn) => filterFn(name || '')));
        })
      )
      .subscribe((list) => {
        if (list) {
          this.setActiveAlist(list.id || '');
        }
      });

    route.queryParamMap
      .pipe(
        switchMap((queries) => {
          const list = queries?.get('list');
          if (list) {
            return of(undefined);
          } else {
            return this.clientAlist$;
          }
        })
      )
      .subscribe((list) => {
        if (list) {
          // router.navigate(['.'], {
          //   queryParams: {
          //     list: list.name,
          //   },
          //   queryParamsHandling: 'merge',
          //   replaceUrl: true,
          // });
        }
      });
  }

  setClientAlist(alist: AlistRepresentation) {
    return this._store.dispatch(new SetClientAlist(alist));
  }

  setServerAlist(alist: AlistRepresentation) {
    return this._store.dispatch(new SetServerAlist(alist));
  }

  setActiveAlist(id: string) {
    return this._store.dispatch(new SetActiveAlistId(id));
  }

  setActiveAlistLanguageCode(languageCode: string) {
    return this._store.dispatch(new SetActiveAlistLanguageCode(languageCode));
  }

  reset() {
    this._store.selectOnce(AlistState.serverAlists).subscribe((obj) => {
      const alists = Object.values(obj);
      this._setServerAlists(alists);
      this._setClientAlists(alists);
    });
  }

  save(id?: string) {
    defer(() => {
      if (id) {
        return this._store
          .select(AlistStateQuery.clientAlistById)
          .pipe(map((filterFn) => filterFn(id || '')));
      } else {
        return this.clientAlist$;
      }
    })
      .pipe(
        first(),
        switchMap((alist) => {
          id = alist?.id;
          return this._alists.updateAlist(alist?.id || '', alist || {});
        })
      )
      .subscribe({
        next: (alist) => {
          this.setServerAlist(<AlistRepresentation>alist);
          this._clearAlistError(id || '');
          this.saveTimestamp$.next(new Date());
        },
        error: (err) => {
          this.alistError(id || '', err);
        },
      });
  }

  removeAlist(alistId: string) {
    return this._store.dispatch(new RemoveAlist(alistId));
  }

  alistError(id: string, error: HttpErrorResponse): void {
    let errorMessage = error?.statusText;
    if (typeof error?.error === 'string') {
      errorMessage = this._alistErrorKey(error.error);
    }
    const errors = this.errors$.value;
    errors[id] = errorMessage || this._translateService.instant('error.SAVE_FAILED.message');
    this.errors$.next(errors);
  }

  private _setClientAlists(alists: AlistRepresentation[]) {
    return this._store.dispatch(new SetClientAlists(alists));
  }

  private _setServerAlists(alists: AlistRepresentation[]) {
    return this._store.dispatch(new SetServerAlists(alists));
  }

  private _alistErrorKey(error: string): string {
    switch (error) {
      case 'INVALID_ALIST_NAME':
        return this._translateService.instant('name.error.invalid');
      case 'ALIST_ALREADY_EXISTS':
        return this._translateService.instant('name.error.taken');
      case 'INVALID_TIER':
        return this._translateService.instant('error.INVALID_TIER.message');
      default:
        return error;
    }
  }

  private _clearAlistError(id: string): void {
    const errors = this.errors$.value;
    errors[id] = '';
    this.errors$.next(errors);
  }
}
