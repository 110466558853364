import {AlistRepresentation} from '@aztrix/sdk';

export class SetClientAlist {
  static readonly type = '[alist] set client alist';
  constructor(public alist: AlistRepresentation) {}
}

export class SetServerAlist {
  static readonly type = '[alist] set server alist';
  constructor(public alist: AlistRepresentation) {}
}

export class SetActiveAlistId {
  static readonly type = '[alist] set active alist id';
  constructor(public alistId: string) {}
}

export class SetActiveAlistLanguageCode {
  static readonly type = '[alist] set active alist language code';
  constructor(public languageCode: string) {}
}

export class SetAlistOwnerId {
  static readonly type = '[alist] set alist owner id';
  constructor(public ownerId: string) {}
}

export class RemoveAlist {
  static readonly type = '[alist] remove alist';
  constructor(public id: string) {}
}

export class SetClientAlists {
  static readonly type = '[alist] set client alists';
  constructor(public alists: AlistRepresentation[]) {}
}

export class SetServerAlists {
  static readonly type = '[alist] set server alists';
  constructor(public alists: AlistRepresentation[]) {}
}
